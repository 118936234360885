<template>
	<div>
		<BaseLoading v-if="list.isLoading" is-full-page />
		<main class="main-wrapper">
			<!-- content -->
			<section>
				<CRow>
					<CCol>
						<h4 class="mb-4">
							General Settings
						</h4>
					</CCol>
				</CRow>
				<OrderAutoCompleteExcludeSKU
					:ref="ORDER_AUTO_COMPLETE_REFS.EXCLUDE_SKU"
					:exclude-sku.sync="from.excludeSku"
				/>
			</section>
		</main>
		<BaseActionPanelStickyFooter
			:disabled-confirm="list.isLoading"
			button-confirm-text="Save Change"
			@onCancel="handleCancel"
			@onConfirm="handleConfirm"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ROUTE_NAME } from '../../../enums/route';
import OrderAutoCompleteExcludeSKU from '../../../components/order/autoComplete/OrderAutoCompleteExcludeSKU.vue';

export default {
	name: 'OrderAutoComplete',
	components: {
		OrderAutoCompleteExcludeSKU,
	},
	data() {
		return {
			ORDER_AUTO_COMPLETE_REFS: {
				EXCLUDE_SKU: 'EXCLUDE_SKU',
			},
			from: {
				excludeSku: [],
			},
		};
	},
	computed: {
		...mapState('orderAutoComplete', {
			list: 'list',
		}),
	},
	async mounted() {
		await this.fetchList();
		// initial data
		this.initialFrom();
	},
	methods: {
		...mapActions({
			fetchList: 'orderAutoComplete/getOrderAutoCompleteList',
			updateList: 'orderAutoComplete/updateOrderAutoCompleteList',
		}),
		initialFrom() {
			if (!this.list) {
				return;
			}
			this.from = this.list.data;
		},
		handleCancel() {
			this.$router.push({ name: ROUTE_NAME.MARKETING });
		},
		async handleConfirm() {
			// to save current data
			const payload = this.from;
			await this.updateList(payload);
		},
	},
};
</script>
